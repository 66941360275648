// import { createStore } from 'vuex'
// import TaskCard from './modules/TaskCard.js';
// import globalVar from './modules/var.js'

// // Create store
// export default createStore({
//   modules: {
//     TaskCard, globalVar
//   }
// });
import { defineStore } from "pinia";
import axios from "axios";

export const useStore = defineStore("Store", {
    state: () => ({
        assessmentId: "",
        isNavbarWhite: false,
        isLoadingQuestions: true,
        customQuestionsCompany: [],
        customQuestions: [],
        allCustomQuestions: [],
        Logged: "none",
        company_id: "",
        //adminLogged: false,
        company_name: "",
        admin_name: "",
        recruiterEmail: "",
        companyCredit: 0,
        companyPlan: "",
        showSubscriptions: false,
        companyAssessments: [],
        projects: [],
        premium: false,
        companyLogo: "",
        articles: [],
        articlesAdmin: [],
        loadingArticles: false,
        candidates: [],
        selectedCandidates: [],
        candidatesResults: [],
        evaluations: [],
        invitations: [],
        showWindow: false,
        isLoading: true,
        archivedCandidates: [],
        invitedCandidates: [],
        archivedCandidatesInfo: [],
        candidateActivity: {},
        candidateInfoAct: {},
        screenersQst: [],
    }),
    getters: {
        getisLogged: (state) => state.Logged,
        getIsNavbarWhite: (state) => state.isNavbarWhite,
        getCompanyName: (state) => state.company_name,
        getAdminName: (state) => state.admin_name,
        getCompanyInitials: (state) => {
            if (state.company_name) {
                const initials = state.company_name.split(" ").reduce((prev, curr) => {
                    return prev + curr.charAt(0).toLocaleUpperCase();
                }, "");
                return initials;
            }
            if (state.admin_name) {
                const initials = state.admin_name.split(" ").reduce((prev, curr) => {
                    return prev + curr.charAt(0).toLocaleUpperCase();
                }, "");
                return initials;
            }
            return null;
        },
        getAssessments: (state) => state.companyAssessments,
        getProjects: (state) => state.projects,
        getArticles: (state) => state.articles,
        getArticlesAdmin: (state) => state.articlesAdmin,
        getLoadingArticles: (state) => state.loadingArticles,
        getArticleData: (state) => (id) => {
            const artic = state.articles.find((blog) => blog._id === id);
            return artic;
        },
        getProjectList: (state) => {
            return state.projects
                .filter((pr) => pr.project_status === "Active")
                .map((project) => ({
                    name: project.name,
                    id: project._id,
                    assessments: project.assessments,
                }));
        },
        getAllProjectList: (state) => {
            return state.projects.map((project) => ({
                name: project.name,
                id: project._id,
                assessments: project.assessments,
            }));
        },
        getBarData: (state) => {
            const barData = state.selectedCandidates.map((candidate) => {
                const data = {
                    name: candidate.First_name + " " + candidate.Last_name,
                    results: candidate.results,
                    _id: candidate._id,
                };
                return data;
            });

            return barData;
        },
        getSpiderChartData: (state) => {
            const barData = state.selectedCandidates.map((candidate) => {
                const data = {
                    name: candidate.First_name + " " + candidate.Last_name,
                    results: candidate.results,
                    _id: candidate._id,
                };
                return data;
            });
            return barData;
        },
        getDistributionData: (state) => (projectId) => {
            function calculateDateDiff(candidate, invite) {
                const inviteDate = new Date(invite?.createdAt);
                const passDate = new Date(candidate?.createdAt);
                inviteDate.setUTCHours(0, 0, 0, 0);
                passDate.setUTCHours(0, 0, 0, 0);
                const dateDiff = Math.floor((passDate - inviteDate) / (1000 * 60 * 24));
                return dateDiff;
            }
            const selectedProject = state.projects.find((project) => {
                return project._id === projectId;
            });
            const selectedProjectCandidates = state.candidatesResults.filter((candidate) => {
                return candidate.projectId === selectedProject._id;
            });
            const selectedProjectInvitations = state.invitations.filter((invitaion) => {
                return invitaion.projectId === projectId || "";
            });

            const datesArray = selectedProjectCandidates.map((candidate) => {
                const candidateInvite = selectedProjectInvitations.find((invite) => invite.Email === candidate.candidate);
                return calculateDateDiff(candidate, candidateInvite);
            });
            return [
                { label: "1 Day", value: datesArray.filter((date) => date <= 1).length },
                { label: "2 Days", value: datesArray.filter((date) => date === 2).length },
                { label: "3 Days", value: datesArray.filter((date) => date >= 3).length },
            ];
        },
    },
    actions: {
        handleIntersection(entries) {
            const isIntersecting = entries[0].isIntersecting;
            this.isNavbarWhite = isIntersecting;
        },

        updateScreeners(screeners) {
            this.screenersQst = [...screeners];
        },

        observeIntersection() {
            const targetElement = document.getElementById("blue-section");

            if (targetElement) {
                const observer = new IntersectionObserver(
                    (entries) => {
                        this.handleIntersection(entries);
                    },
                    {
                        threshold: 0.1,
                        root: null,
                        rootMargin: "0px",
                    },
                );

                observer.observe(targetElement);
            }
        },
        setPremium(premiumStatus) {
            this.premium = premiumStatus;
        },
        fetchCustomCompany() {
            axios
                .get("https://server.go-platform.com/CustomQuestion/company", {
                    withCredentials: true,
                })
                .then((res) => {
                    this.customQuestionsCompany = res.data;
                    this.allCustomQuestions = [...this.allCustomQuestions, ...this.customQuestionsCompany];
                    this.isLoadingQuestions = false;
                });
        },
        fetchCustomGoPlatform() {
            axios
                .get("https://server.go-platform.com/CustomQuestion/get", {
                    withCredentials: true,
                })
                .then((res) => {
                    this.customQuestions = res.data;
                    this.allCustomQuestions = [...this.allCustomQuestions, ...this.customQuestions];
                    this.isLoadingQuestions = false;
                });
        },
        userLogged() {
            axios
                .get("https://server.go-platform.com/user/isLoggedIn", {
                    withCredentials: true,
                })
                .then((response) => {
                    this.Logged = response.data.isLogged;
                    this.setPremium(response.data.premium);
                    console.log({ LOGGED: response });
                    if (this.Logged == "user" || this.Logged == "admin") {
                        this.getCompanyCredit();
                    }
                })
                .then(() => {
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 1500);
                })
                .catch((error) => {
                    console.error("Error:", error);
                    this.isLoading = false;
                });
        },
        setCompanyName(company_name) {
            this.company_name = company_name;
        },
        getCompanyCredit() {
            axios
                .get("https://server.go-platform.com/company/getCredit", {
                    withCredentials: true,
                    headers: {
                        "Cache-Control": "no-cache", // or 'no-store'
                    },
                })
                .then((response) => {
                    if (this.Logged == "user") {
                        this.companyCredit = response.data.credit;
                        this.company_name = response.data.company_name;
                        this.company_id = response.data._id;
                        this.companyLogo = response.data.company_logo;
                        this.recruiterEmail = response.data.recruiterEmail;
                        this.companyPlan = response.data.plan;
                    } else if (this.Logged == "admin") {
                        this.admin_name = response.data.admin_name;
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        },

        toggleSubscriptions() {
            this.showSubscriptions = !this.showSubscriptions;
        },

        getCompanyAssessments() {
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `https://server.go-platform.com/AssessmentTest/companyAssessments`,
                headers: {},
                withCredentials: true,
            };

            axios
                .request(config)
                .then((response) => {
                    this.companyAssessments = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async fetchProjects(option = "all", page = 1) {
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `https://server.go-platform.com/projects/companyProjects`,
                withCredentials: true,
                headers: {
                    "Cache-Control": "no-cache", // or 'no-store'
                },
                params: {
                    option: option,
                    page: page,
                },
            };

            await axios
                .request(config)
                .then((response) => {
                    this.projects = response.data;
                    if (this.projects.length === 0) {
                        if (localStorage.getItem("windowShown")) {
                            this.showWindow = JSON.parse(localStorage.getItem("windowShown"));
                        } else {
                            this.showWindow = true;
                            localStorage.setItem("windowShown", "true");
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchArticles() {
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `https://server.go-platform.com/articles/data`,
                withCredentials: true,
                headers: {
                    "Cache-Control": "no-cache", // or 'no-store'
                },
            };
            this.loadingArticles = true;
            axios
                .request(config)
                .then((response) => {
                    this.articles = response.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingArticles = false;
                });
        },
        fetchArticlesAdmin() {
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `https://server.go-platform.com/articles/data/admin`,
                withCredentials: true,
                headers: {
                    "Cache-Control": "no-cache", // or 'no-store'
                },
            };
            this.loadingArticles = true;
            axios
                .request(config)
                .then((response) => {
                    this.articlesAdmin = response.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingArticles = false;
                });
        },
        async fetchCandidates() {
            axios
                .get("https://server.go-platform.com/company/candidates", {
                    withCredentials: true,
                })
                .then((response) => {
                    if (response.status == 400) {
                        return;
                    }

                    this.candidates = response.data.candidatesData;

                    this.candidatesResults = response.data.candidatesScores;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        fetchInvitations() {
            axios
                .get("https://server.go-platform.com/company/invitations", {
                    withCredentials: true,
                })
                .then((response) => {
                    this.invitations = response.data.invitationsSent;
                });
        },
        async getArchivedCandidates() {
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: "https://server.go-platform.com/company/archivedCandidates",
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            };

            axios
                .request(config)
                .then((response) => {
                    this.archivedCandidates = response.data.candidatesScores;
                    this.archivedCandidatesInfo = response.data.candidatesData;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async getInvitedCandidates() {
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: "https://server.go-platform.com/company/InvitedCandidates",
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            };

            axios
                .request(config)
                .then((response) => {
                    this.invitedCandidates = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        addCandidate(cand) {
            if (this.selectedCandidates.includes(cand)) {
                this.selectedCandidates = this.selectedCandidates.filter((candidate) => candidate._id !== cand._id);
            } else {
                if (this.selectedCandidates.length < 3) {
                    this.selectedCandidates = [...this.selectedCandidates, cand];
                } else alert("You can compare up to 3 candidates only");
            }
        },
        transformEvaluations(projectId) {
            const selectedProject = this.projects.find((project) => {
                return project._id === projectId;
            });

            const selectedProjectCandidates = this.candidatesResults.filter((candidate) => {
                return candidate.projectId === selectedProject._id;
            });

            const selectedCandidates = this.candidates.filter((candidate) => {
                return selectedProjectCandidates.find((cand) => cand.candidate === candidate.Email);
            });

            const selectedCandidatesEvaluation = selectedCandidates.map((candidate) => {
                const evaluation = selectedProjectCandidates.find((cand) => cand.candidate === candidate.Email);
                const candidateResult = { ...candidate, ...evaluation };

                let score = 0;
                candidateResult.results.forEach((element) => {
                    score += element.rangesPoint ? (element.rangesPoint * 100) / (element.quesionsNbr * 5) : (element.totalPoints * 100) / element.quesionsNbr;
                });
                const averageScore = score / candidateResult.results.length;
                const roundedScore = averageScore.toFixed(2);
                return { ...candidate, ...evaluation, avgScore: roundedScore };
            });

            this.evaluations = selectedCandidatesEvaluation;
        },
        resetSelectedCandidates() {
            this.selectedCandidates = [];
        },
        setNavbarWhite(state, value) {
            state.isNavbarWhite = value;
        },
        createDistributionData(projectId) {
            function calculateDateDiff(candidate, invite) {
                const inviteDate = new Date(invite?.createdAt);
                const passDate = new Date(candidate?.createdAt);
                inviteDate.setUTCHours(0, 0, 0, 0);
                passDate.setUTCHours(0, 0, 0, 0);
                const dateDiff = Math.floor((passDate - inviteDate) / (1000 * 60 * 24));
                return dateDiff;
            }
            const selectedProject = this.projects.find((project) => {
                return project._id === projectId;
            });
            const selectedProjectCandidates = this.candidatesResults.filter((candidate) => {
                return candidate.projectId === selectedProject?._id;
            });
            const selectedProjectInvitations = this.invitations.filter((invitaion) => {
                return invitaion.projectId === projectId || "";
            });

            const datesArray = selectedProjectCandidates.map((candidate) => {
                const candidateInvite = selectedProjectInvitations.find((invite) => invite.Email === candidate.candidate);
                return calculateDateDiff(candidate, candidateInvite);
            });
            return [
                { label: "1 Day", value: datesArray.filter((date) => date <= 1).length },
                { label: "2 Days", value: datesArray.filter((date) => date === 2).length },
                { label: "3 Days", value: datesArray.filter((date) => date >= 3).length },
            ];
        },
    },
});
